<template>
  <div style="background: #fff;height: 100%;">
    <van-nav-bar
      :title="customerName +'的提成设置'"
      left-arrow
      :fixed="true"
      :z-index="66"
      @click-left="back"
    ></van-nav-bar>
    <div style="padding: 66px 30px 0 20px;" v-show="!pleshow">
      <div class="hes">
        <span class="names">工时提成</span>
        <input class="ips" v-model="list.percentageTimeRate" type="text" />
        <span class="bai">%</span>
      </div>
      <div class="hes">
        <span class="names">一般配件提成</span>
        <input class="ips" v-model="list.percentagePartRate" type="text" />
        <span class="bai">%</span>
      </div>
      <div class="hes">
        <span class="names">特殊配件提成</span>
        <van-button type="default" class="right" @click="tianjia" size="mini">添加</van-button>
      </div>
      <div class="hes" v-for="(item,idx) in list.tokerPartPercentageDOS" :key="idx">
        <span class="names">{{item.partName}}</span>
        <input class="ips" v-model="item.percentage" type="text" />
        <span class="bai">%</span>
        <span class="right" style="color:red;" @click="PJshanchu(idx)">删除</span>
        <!-- <van-button type="danger" plain  style="margin-top: 3px;" class="right" size="mini">删除</van-button> -->
      </div>
    </div>
    <van-button class="botbaoc" type="primary" @click="tobaocun">保存</van-button>
    <van-popup
      v-model="show"
      :close-on-click-overlay="false"
      closeable
      style="z-index: 99 !important;"
    >
      <div class="hes1">添加特殊配件提成</div>
      <div class="hes2">
        <span class="hes_lis">配件名称:</span>
        <input class="inputs" type="text" v-model="pjname" />
      </div>

      <div class="bott">
        <van-button class="vbott" type="danger" @click="show = false" size="small">退 出</van-button>
        <van-button class="vbott" type="primary" size="small" @click="addxiaxian">添 加</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import userwei from "../../api/user";
export default {
  data() {
    return {
      list: {},
      show: false,
      pleshow: false,
      pjname: "",
      carNo: "",
      name: "",
      phone: "",
      customerId: this.$route.query.customerId,
      customerName: this.$route.query.customerName,
      offId: this.$route.query.offId,
      gid: this.$route.query.gid,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      nameList: {}
    };
  },
  methods: {
    //添加下线
    toadd() {
      this.show = true;
    },
    //打开键盘
    getple() {
      this.pleshow = true;
      this.$refs.ple.clickShowKeyboard();
    },
    tianjia() {
      this.show = true;
    },
    //删除
    toshanchu(items) {
      console.log(items);
      // this.carNo = items.carNo;
      Dialog.confirm({
        title: "",
        message:
          "确实要删除车牌【" + items.tokerCarInfoVOList[0].carNo + "】吗？"
      })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
      // this.scshow = true;
    },

    //添加特殊配件
    addxiaxian() {
      if (this.pjname == "" || this.pjname == null) {
        this.$toast("请输入配件名称");
        return;
      }
      let data = {
        partName: this.pjname,
        percentage: "",
        gid: this.gid,
        tokerId: ""
      };
      this.list.tokerPartPercentageDOS.push(data);
      this.pjname = "";
      this.show = false;
    },
    tobaocun() {
      this.list.gid = this.gid
      userwei.updatePercentagenew(this.list).then(res => {
        if (res.code == 200) {
          this.$toast("保存提成设置成功！");
          this.onLoad();
        }
      });
    },
    //删除配件
    PJshanchu(idx) {
      this.list.tokerPartPercentageDOS.splice(idx, 1);
    },
    //输入车牌
    getPlate(val) {
      console.log(val);
      this.carNo = val;
      this.pleshow = false;
    },
    onLoad() {
      userwei.getResultnew({ offid: this.offId, gid: this.gid }).then(res => {
        this.list = res.data;
      });
    },

    back() {
      this.$router.go(-1);
    },
    totuijianxq(item) {
      this.$router.push({
        name: "indextwo",
        query: {
          customerId: this.offId,
          carNo: item.carNo,
          customerName: item.customerName,
          phone: item.phone,
          gid: item.gid,
          showisok: "消费"
        }
      });
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.creatQrCode();
  }
};
</script>

<style lang="less" scoped>
.hes {
  font-size: 14px;
  line-height: 30px;
  margin-top: 10px;
  overflow: hidden;
}
.names {
  display: inline-block;
  width: 110px;
  text-align: right;
  margin-right: 10px;
}
.ips {
  width: 100px;
  border: 1px solid #dcdcdc;
  border-right: none;
  padding: 0 10px;
  // border-radius: 4px;
}
.bai {
  display: inline-block;

  width: 40px;
  text-align: center;
  background: #f5f7fa;
  border: 1px solid #dcdcdc;
}
.botbaoc {
  position: fixed;
  width: 100%;
  bottom: 0;
  line-height: 40px;
  height: 40px;
}
.hes1 {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  padding: 10px 0 5px 0;
}
.hes2 {
  overflow: hidden;
  padding-top: 10px;
}
.van-popup {
  width: 70%;
  font-size: 13px;
  padding: 10px 0 30px 0;
}
.hes_lis {
  display: inline-block;
  width: 70px;
  text-align: right;
  margin-right: 5px;
}
.inputs {
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  padding-left: 4px;
  padding: 0 5px;
}
.bott {
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
}
.vbott {
  margin: 0 5px;
}
</style>
